<template>
	<div class="comment">
		<v-avatar color="#494949">
			<v-icon color="#fff">account_circle</v-icon>
		</v-avatar>

		<div class="date-text">
			<div class="date-time">{{ dateTime }}</div>

			<div class="text">{{ comment.text }}</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "CommentCardComponent",
	props: {
		comment: {
			type: Object,
			required: true,
			default: null
		}
	},
	computed: {
		dateTime() {
			if (this.comment) {
				const date = this.comment.creationDate;

				return `${date.toLocaleDateString("ru-RU")} ${date
					.toLocaleTimeString()
					.substr(0, 5)}`;
			}

			return "";
		}
	}
};
</script>

<style scoped lang="scss">
.comment {
	display: flex;
	align-items: center;

	.date-text {
		margin-left: 10px;

		.date-time {
			font-weight: 300;
			color: #a4a4a4;
		}

		.text {
			font-size: 18px;
		}
	}
}
</style>
