<template>
	<div class="ticket-page">
		<div class="header">
			<div class="header-body wrapper">
				<div class="ticket-info">
					<div class="station-name">{{ substationName }}</div>

					<div class="date">
						<v-icon class="icon">event</v-icon>
						<div class="date-value">{{ ticketDate }}</div>
					</div>

					<div class="time">
						<v-icon class="icon">schedule</v-icon>
						<div class="time-value">{{ ticketTime }}</div>
					</div>
				</div>

				<div class="d-flex align-center">
					<div
						v-if="ticket && ticket.isArchive && ticket.archivationDate"
						style="color: #a7a7a7; margin-right: 15px"
					>
						Документ был архивирован
						{{ ticket.archivationDate.toLocaleDateString("ru-RU") }}
					</div>

					<v-btn
						outlined
						color="primary"
						class="archive-button"
						@click="$refs.archiveModal.show()"
						:disabled="isArchive"
					>
						<v-icon left>archive</v-icon>
						Архивировать

						<ArchiveTicketModalComponent
							ref="archiveModal"
							:ticket-id="ticketId"
							:potentialProblems="potentialProblems"
						/>
					</v-btn>
				</div>
			</div>
		</div>

		<div class="wrapper">
			<ChartComponent class="chart" @kek="kekHandle" @kek2="magnitudeHandle" />

			<v-row class="comments-problems">
				<v-col v-if="ticket">
					<span class="section-title">Обсуждение:</span>

					<CommentCardComponent
						class="comment"
						v-for="comment in ticket.comments"
						:key="comment.id"
						:comment="comment"
					/>

					<div class="add-comment" v-if="!ticket.isArchive">
						<v-text-field
							class="add-comment-field"
							placeholder="Комментарий"
							v-model="newCommentInput"
							@keydown.enter="saveComment"
						/>
						<v-icon class="send-icon" @click="saveComment">send</v-icon>
					</div>
				</v-col>

				<v-col>
					<span class="section-title">Возможные проблемы:</span>

					<div class="perform-analysis" v-if="false">
						<v-btn class="analyse-button" color="#005B9C" :disabled="false">
							<v-icon left>biotech</v-icon>
							Анализировать
						</v-btn>

						<div class="is-processing" v-if="false">
							<v-icon size="20" class="icon">error</v-icon>
							Анализ файла в процессе...
						</div>
					</div>

					<div class="potential-problems" v-if="isArchive">
						<ul>
							<li v-for="(problem, i) in problems" :key="i">{{ problem }}</li>
						</ul>
					</div>
					<div v-else v-for="issue in potentialProblems">
						{{ issue }}
					</div>

					<div v-if="ticket && ticket.dispetcherComment && isArchive">
						<div class="section-title mt-5">Комментарий диспетчера:</div>
						<div>{{ ticket.dispetcherComment }}</div>
					</div>

					<span class="section-title mt-5">Средние значения частоты по каналам:</span>
					<div v-for="magnitude in magnitudes">
						{{ magnitude[0] }}: {{ magnitude[1] }} Hz
					</div>
				</v-col>
			</v-row>
		</div>
	</div>
</template>

<script>
import ChartComponent from "@/components/ChartComponent";
import CommentCardComponent from "@/components/CommentCardComponent";
import ArchiveTicketModalComponent from "@/components/ArchiveTicketModalComponent";
import TicketService from "@/services/TicketService";
import TicketCommentService from "@/services/TicketCommentService";
import { mapActions } from "vuex";

export default {
	name: "TicketPage",
	components: { ArchiveTicketModalComponent, CommentCardComponent, ChartComponent },
	data: () => ({
		ticketId: 0,
		ticket: null,
		newCommentInput: "",
		potentialProblems: [],
		magnitudes: []
	}),
	async mounted() {
		this.ticketId = parseInt(this.$route.params.id);

		await this.markAsVisited(this.ticketId);
		this.ticket = await TicketService.getById(this.ticketId);
	},
	computed: {
		ticketDate() {
			if (this.ticket) {
				return this.ticket.creationDate.toLocaleDateString("ru-RU");
			}

			return "";
		},
		ticketTime() {
			if (this.ticket) {
				return this.ticket.creationDate.toLocaleTimeString("ru-RU").substr(0, 5);
			}

			return "";
		},
		substationName() {
			if (this.ticket) {
				return this.ticket.substation ? this.ticket.substation.name : "Empty";
			}

			return "";
		},
		isArchive() {
			if (this.ticket) {
				return this.ticket.isArchive;
			}

			return false;
		},
		problems() {
			if (this.ticket && this.ticket.problems) {
				return this.ticket.problems.split(";");
			}

			return [];
		}
	},
	methods: {
		...mapActions(["markAsVisited"]),
		async saveComment() {
			const comment = await TicketCommentService.addComment(
				this.ticketId,
				this.newCommentInput
			);
			this.newCommentInput = "";

			this.ticket.comments.push(comment);
		},
		kekHandle(data) {
			this.potentialProblems = data;
		},
		magnitudeHandle(data) {
			this.magnitudes = data;
		}
	}
};
</script>

<style scoped lang="scss">
@import "../assets/scss/variables";

.ticket-page {
	.header {
		padding: 20px 0;
		border-bottom: 1px solid $primary;

		.header-body {
			display: flex;
			justify-content: space-between;
		}

		.ticket-info {
			display: flex;
			justify-content: space-between;
			align-items: center;

			.station-name {
				font-size: 20px;
				font-weight: 500;
			}

			.date,
			.time {
				display: flex;
				align-items: center;
				margin-left: 30px;

				.icon {
					margin-right: 5px;
				}
			}
		}
	}

	.chart {
		margin-top: 35px;
	}

	.comments-problems {
		margin-top: 35px;

		.section-title {
			margin-bottom: 20px;
			display: inline-block;
			font-size: 20px;
			border-bottom: 1px solid $primary;
		}

		.comment {
			margin-bottom: 25px;
		}

		.add-comment {
			display: flex;
			align-items: center;
			width: 300px;
			margin-top: 20px;

			.send-icon {
				margin-left: 20px;
				color: $primary;
			}
		}

		.analyse-button {
			display: block;
			color: white;
		}

		.is-processing {
			margin-top: 15px;
			display: flex;
			align-items: center;
			color: $primary;

			.icon {
				margin-right: 5px;
				color: $primary;
			}
		}

		.potential-problems {
			margin-left: 10px;
			font-size: 18px;

			li {
				padding: 8px 0;
			}
		}
	}
}
</style>
