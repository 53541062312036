import httpClient from "@/httpClient";

export default class TicketCommentService {
	static API_ENDPOINT	= "comment";


	static async addComment(ticketId, text) {
		const comment = {
			ticketId,
			text,
			creationDate: new Date()
		}

		await httpClient().post(`${this.API_ENDPOINT}/save`, comment);

		return comment;
	}
}
