<template>
	<div class="chart">
		<v-progress-linear indeterminate color="primary" v-if="isDataLoading" />

		<div v-else>
			<div id="checkbox-place"></div>
			<v-autocomplete
				label="Выбрать осцилограммы"
				:items="charts"
				:item-text="s => s.name"
				:item-value="s => s.id"
				clearable
				multiple
				v-model="charts_selected"
				@change="pushOrRemoveStates()"
			/>
		</div>
    <div id="chart-place"></div>
    <div v-for="issue in textPotentialProblem" >
      {{ issue }}
    </div>
    <div v-for="m in magnitudeText" >
      {{ m }}
    </div>
	</div>
</template>

<script>
import * as d3 from "d3";
import Vue from "vue";

export default {
	name: "ChartComponent",
	data: () => ({
		charts: [],
		charts_selected: [],
		isDataLoading: true,
    amp: {},
    magnitude: {}
	}),
	methods: {
		pushOrRemoveStates() {
			this.handleSelect(this.charts_selected);
		}
	},
  computed: {
    withInterval: function(){
      return Object.entries(this.amp).filter((a)=>{
              return a[1]['intervals'].length > 0;
            })

    },
    textPotentialProblem: function(){
      var res = this.withInterval.map((arr)=>{
        return `Возможно короткое замыкание ${arr[0]} интервал ${arr[1]['intervals']}`
      })
      this.$emit("kek", res);
    },
    magnitudeText: function(){
      this.$emit("kek2", Object.entries(this.magnitude));
    },
  },
	async mounted() {
		var v_comp = this;

		var margin = { top: 10, right: 10, bottom: 30, left: 200 },
			width = 1100 - margin.left - margin.right,
			height = 200 - margin.top - margin.bottom;

		var csvUrl = "https://kav-api.kovalev.team/file/decode?code=" + window.location.pathname.split("/")[window.location.pathname.split("/").length - 1];


		// append the svg object to the body of the page

		function addElement(key) {
			const newDiv = document.createElement("div");
			newDiv.id = key;
			newDiv.setAttribute("name", key);
			newDiv.setAttribute("class", "svgs");
			document.getElementById("chart-place").appendChild(newDiv);
		}

		Vue.prototype.handleSelect = function (global_keys) {
			// if(this.checked) {
			//   global_keys.push(this.name)
			// } else {
			//   global_keys = global_keys.filter((el) => {
			//     return el != this.name;
			//   });
			// }
			var svgs = document.getElementsByClassName("svgs");
			for (var i = svgs.length - 1; i >= 0; --i) {
				svgs[i].remove();
			}
			zoomable = [];
			curr_valuable = [];
			linable = [];
			resetable = [];
			global_keys.map(k => createSvg(k, filtered));
		};

		function addCheckbox(key) {
			v_comp.charts.push({ id: key, name: key });

      if ( Object.entries(amp).filter((a)=>{
              return a[1]['intervals'].length > 0;
            }).map(r=>r[0]).includes(key)) {
        v_comp.charts_selected.push(key)
      }
		}

		var zoomable = [];
		var resetable = [];
		var curr_valuable = [];
		var linable = [];
		var global_keys = [];
		var id = 0;
		var datas;
		var filtered;
    var response;
    var magnitude;
    var amp;


		var data = d3.csv(csvUrl,async data => {

      response = await fetch(`https://kav-api.kovalev.team/file/comtrade/analytics/file?code=${window.location.pathname.split("/")[window.location.pathname.split("/").length - 1]}&type=freq`);
      magnitude = await response.json();
      response = await fetch(`https://kav-api.kovalev.team/file/comtrade/analytics/file?code=${window.location.pathname.split("/")[window.location.pathname.split("/").length - 1]}&type=amp`);
      amp = await response.json();
      v_comp.magnitude = magnitude
      v_comp.amp = amp
      this.isDataLoading = false;

			datas = data.map((d, i) => {
				if (i % 1 == 0) {
					return { index: i / 1, values: d };
				}
			});

			filtered = datas.filter(function (el) {
				return el != null;
			});

			var keys = Object.keys(datas[0].values);

			keys.map(k => addCheckbox(k));
      v_comp.handleSelect(v_comp.charts_selected);
		});




		function createSvg(key, data) {
			id = id + 1;
			var str_id = "mysvg" + id;
			addElement(str_id);

			var svg = d3
				.select("#" + str_id)
				.append("svg")
				.attr("width", width + margin.left + margin.right)
				.attr("height", height + margin.top + margin.bottom)
				.attr("name", "svg-" + key)
				.append("g")
				.attr("transform", "translate(" + margin.left + "," + margin.top + ")");

			// Now I can use this dataset:
			// function(data) {
			// Add X axis --> it is a date format
			var x = d3
				.scaleLinear()
				.domain(
					d3.extent(data, function (d) {
						return d.index;
					})
				)
				.range([0, width]);
			var xAxis = svg
				.append("g")
				.attr("transform", "translate(0," + height + ")")
				.call(d3.axisBottom(x));

			// Add Y axis
			var y = d3
				.scaleLinear()
				.domain([
					d3.min(data, function (d) {
						return +d.values[key];
					}),
					d3.max(data, function (d) {
						return +d.values[key];
					})
				])
				.range([height, 0]);
			var yAxis = svg.append("g").call(d3.axisLeft(y));

			// Add a clipPath: everything out of this area won't be drawn.
			var clip = svg
				.append("defs")
				.append("svg:clipPath")
				.attr("id", "clip")
				.append("svg:rect")
				.attr("width", width)
				.attr("height", height)
				.attr("x", 0)
				.attr("y", 0)
				.style("pointer-events", "all")
				.style("fill", "none");

			// Create the line variable: where both the line and the brush take place
			var line = svg.append("g").attr("clip-path", "url(#clip)");

			line.append("path")
				.datum(data)
				.attr("class", "line") // I add the class line to be able to modify this line later on.
				.attr("fill", "none")
				.attr("stroke", "steelblue")
				.attr("stroke-width", 1.5)
				.attr(
					"d",
					d3
						.line()
						.x(function (d) {
							return x(d.index);
						})
						.y(function (d) {
							return y(d.values[key]);
						})
				);

			// Add the brushing

			// A function that set idleTimeOut to null
			var idleTimeout;
			function idled() {
				idleTimeout = null;
			}

			// A function that update the chart for given boundaries
			zoomable.push(function update(extent) {
				// x.domain(d3.extent(data, function(d) { return d.index; }))
				// line
				//   .select('.line')
				//   .transition()
				//   .attr("d", d3.line()
				//     .x(function(d) { return x(d.index) })
				//     .y(function(d) { return y(d.values[key]) })
				// )
				x.domain([x.invert(extent[0]), x.invert(extent[1])]);
				xAxis.transition().duration(1000).call(d3.axisBottom(x));
				line.select(".line")
					.transition()
					.duration(1000)
					.attr(
						"d",
						d3
							.line()
							.x(function (d) {
								return x(d.index);
							})
							.y(function (d) {
								return y(d.values[key]);
							})
					);
			});
			function updateChart() {
				// What are the selected boundaries?
				var extent = d3.event.selection;
				// If no selection, back to initial coordinate. Otherwise, update X axis domain
				if (!extent) {
					if (!idleTimeout) return (idleTimeout = setTimeout(idled, 350)); // This allows to wait a little bit
					x.domain([4, 8]);
				} else {
					// x.domain([ x.invert(extent[0]), x.invert(extent[1]) ])
					line.select(".brush").call(brush.move, null);
					zoomable.map(f => f(d3.event.selection));
				}
				//
				// // Update axis and line position
				// xAxis.transition().duration(1000).call(d3.axisBottom(x))
				// line
				//     .select('.line')
				//     .transition()
				//     .duration(1000)
				//     .attr("d", d3.line()
				//       .x(function(d) { return x(d.index) })
				//       .y(function(d) { return y(d.values[key]) })
				//     )
			}

			// If user double click, reinitialize the chart
			resetable.push(function reset_zoom() {
				x.domain(
					d3.extent(data, function (d) {
						return d.index;
					})
				);
				xAxis.transition().call(d3.axisBottom(x));
				line.select(".line")
					.transition()
					.attr(
						"d",
						d3
							.line()
							.x(function (d) {
								return x(d.index);
							})
							.y(function (d) {
								return y(d.values[key]);
							})
					);
			});
			svg.on("dblclick", () => {
				resetable.map(f => f());
			});
			svg.append("text")
				.attr("text-anchor", "start")
				.attr("y", 50)
				.attr("x", -200)
				.text(function (d) {
					return key;
				});

			curr_valuable.push(
				svg
					.append("text")
					.attr("text-anchor", "start")
					.attr("name-key", key)
					.attr("y", 70)
					.attr("x", -200)
					.text(function (d) {
						return key;
					})
			);
			// .style("fill", function(d){ return color(key) })
			//

			var bisect = d3.bisector(function (d) {
				return d.index;
			}).left;
			//
			//       // Create the circle that travels along the curve of chart
			linable.push(
				svg
					.append("g")
					.append("line")
					.style("fill", "none")
					.attr("stroke", "black")
					.style("opacity", 1)
					.attr("name-key", key)
			);
			//
			// // // Create the text that travels along the curve of chart
			// var focusText = svg
			//   .append('g')
			//   .append('text')
			//     .style("opacity", 0)
			//     .attr("text-anchor", "left")
			//     .attr("alignment-baseline", "middle")

			// Add brushing
			var brush = d3
				.brushX() // Add the brush feature using the d3.brush function
				.extent([
					[0, 0],
					[width, height]
				]) // initialise the brush area: start at 0,0 and finishes at width,height: it means I select the whole graph area
				.on("end", updateChart);
			// Each time the brush selection changes, trigger the 'updateChart' function

			line.append("g")
				.attr("class", "brush")
				.call(brush)
				// .on('mouseover', mouseover)
				.on("mousemove", mousemove)
				.on("mouseout", mouseout)
				.on("mouseout", mouseover);

			//
			//   // What happens when the mouse move -> show the annotations at the right positions.
			function mouseover() {
				// focus.style("opacity", 1)
				// focusText.style("opacity",1)
			}
			//
			function mousemove() {
				// recover coordinate we need
				var x0 = x.invert(d3.mouse(this)[0]);
				var i = bisect(data, x0, 1);
				var selectedData = data[i];
				var t_key;
				curr_valuable.map(cv => {
					var t_key = cv.attr("name-key");
					cv.text(
						"x:" + selectedData.index + "  -  " + "y:" + selectedData.values[t_key]
					);
				});
				linable.map(line => {
					t_key = line.attr("name-key");
					line.attr("x1", x(selectedData.index))
						.attr("x2", x(selectedData.index))
						.attr("y1", -1000)
						.attr("y2", 1000)
						.style("pointer-events", "none");
				});

				// focusText
				//   .html("x:" + selectedData.index + "  -  " + "y:" + selectedData.values[key])
				//   .attr("x", x(selectedData.index)+15)
				//   .attr("y", y(selectedData.values[key]))
			}
			function mouseout() {
				focus.style("opacity", 1);
				// focusText.style("opacity", 0)
			}
			//
		}
	}
};
</script>

<style scoped></style>
